import BasePlugin from '../BasePlugin'

export default class AddViolationEliminationTermByOrder extends BasePlugin {
  async execute () {
    let orderId = {
      'order_id': this.context.getModel().id
    }

    if (!this.context.getModel().attr_437_) {
      this.context.$msgbox({
        type: 'info',
        message: 'Заполните срок выполнения предписания'
      })
    } else {
      this.context.$msgbox({
        title: 'Установление срока предписания',
        message: 'У всех нарушений будет установлен указанный в предписании срок устранения, Вы уверены?',
        showCancelButton: true,
        confirmButtonText: 'Да',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddViolationEliminationTermByOrderCommand`,
              orderId,
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
            done()
            this.context.$msgbox.close()
          } else {
            this.context.$msgbox.close()
          }
        }
      })
    }
  }
}
